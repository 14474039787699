import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation, createBrowserRouter, createRoutesFromElements, RouterProvider, Outlet, Route, Link, Form } from 'react-router-dom';
import ReactJkMusicPlayer from 'react-jinke-music-player'
import 'react-jinke-music-player/assets/index.css'
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faXTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { Helmet } from "react-helmet"
import { MediaDetailModal, VideoModal, ImageModal, FileModal } from './components'

import './App.css';
import useSharedFormState from './store';
import Home from './webpages/Home';
import About from './webpages/About';
import Essentials from './webpages/Essentials';
import Essential from './webpages/Essential';
import Microsites from './webpages/Microsites';
import Collections from './webpages/Collections';
import Collection from './webpages/Collection';
import Documents from './webpages/Documents';
import DocumentsBiography from './webpages/DocumentsBios';
import DocumentsIconographies from './webpages/DocumentsIconographies';
import DocumentsMusicSheets from './webpages/DocumentsMusicSheets';
import DocumentsPublications from './webpages/DocumentsPublications';
import DocumentsHistoricArchives from './webpages/DocumentsHistoricArchives';
import Children from './webpages/Children';
import ChildrenStories from './webpages/ChildrenStories';
// import ChildrenImages from './webpages/ChildrenImages';
import ChildrenBooks from './webpages/ChildrenBooks';
import ChildrenMusic from './webpages/ChildrenMusic';
import ChildrenRadio from './webpages/ChildrenRadio';
import ChildrenVideos from './webpages/ChildrenVideos';
import FromHome from './webpages/FromHome';
import Iberoamerica from './webpages/Iberoamerica';
import Novelties from './webpages/Novelties';
import Live from './webpages/Live';
import LiveEvent from './webpages/LiveEvent';
import Authors from './webpages/Authors';
import Author from './webpages/Author';
import Search from './webpages/Search';
import Multimedia from './webpages/Multimedia';
import NotFound from './webpages/NotFound';

const TRACKING_ID = "G-HY5R66E0H1";
ReactGA.initialize(TRACKING_ID, { testMode: false });

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

let router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route path="acerca-de" element={<About />} />
      <Route path="imprescindibles" element={<Essentials />} />
      <Route path="imprescindibles/:url1" element={<Essential />} />
      <Route path="imprescindibles/:url1/:url2" element={<Essential />} />
      <Route path="imprescindibles/:url1/:url2/:url3" element={<Essential />} />
      <Route path="micrositios" element={<Microsites />} />
      <Route path="micrositios/:url" element={<Microsites />} />
      <Route path="colecciones" element={<Collections />} />
      <Route path="colecciones/:url" element={<Collection />} />
      <Route path="documentos" element={<Documents />} />
      <Route path="documentos/biografias" element={<DocumentsBiography />} />
      <Route path="documentos/biografias/:url" element={<DocumentsBiography />} />
      <Route path="documentos/iconografias" element={<DocumentsIconographies />} />
      <Route path="documentos/partituras" element={<DocumentsMusicSheets />} />
      <Route path="documentos/publicaciones" element={<DocumentsPublications />} />
      <Route path="documentos/archivo-historico" element={<DocumentsHistoricArchives />} />
      <Route path="desde-la-fonoteca" element={<FromHome />} />
      <Route path="desde-la-fonoteca/:url" element={<FromHome />} />
      <Route path="iberoamerica-suena" element={<Iberoamerica />} />
      <Route path="iberoamerica-suena/:url" element={<Iberoamerica />} />
      <Route path="novedades" element={<Novelties />} />
      <Route path="novedades/:url" element={<Novelties />} />
      <Route path="infancias" element={<Children />} />
      <Route path="infancias/cuentos" element={<ChildrenStories />} />
      {/* <Route path="infancias/imagenes" element={<ChildrenImages />} /> */}
      <Route path="infancias/libros" element={<ChildrenBooks />} />
      <Route path="infancias/musica" element={<ChildrenMusic />} />
      <Route path="infancias/radio" element={<ChildrenRadio />} />
      <Route path="infancias/videos" element={<ChildrenVideos />} />
      <Route path="en-directo" element={<Live />} />
      <Route path="en-directo/evento/:url" element={<LiveEvent />} />
      <Route path="en-directo/:url" element={<Live />} />
      <Route path="autores" element={<Authors />} />
      <Route path="autores/:url" element={<Author />} />
      <Route path="busqueda" element={<Search />} />
      <Route path="media/:id" element={<Multimedia />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

export default function App() {
  
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  
  // const { player, setPlayer,audioList,setAudioList,playerOptions,setPlayerOptions } = useSharedFormState();
  const { player } = useSharedFormState();

  let handleKeyDown = (event) => {
    if (event.code === "Space") {
      if (!event.srcElement || event.srcElement.type !== 'search') {
        event.preventDefault();
        // console.log('Space pressed '+player);
        if(player) player.togglePlay();
      }
    }
  };
  
  let handleContextMenu = (event) => {
    event.preventDefault(); // prevents the default right-click menu from appearing
  }
  
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener('contextmenu', handleContextMenu);
  }, [player]);

  return <RouterProvider router={router} fallbackElement={<p>No se encontró</p>} />;

}

// Layout
export function Layout() {

  // const { player, setPlayer,audioList,setAudioList,playerOptions,setPlayerOptions } = useSharedFormState();
  const { setPlayer,audioList,setAudioList,playerOptions,setPlayerOptions } = useSharedFormState();

  const onAudioListsChange = (currentPlayId,audioLists,audioInfo) => {
    setAudioList(audioLists);
    setPlayerOptions({
        ...playerOptions,
        ...{ mode: 'full' }
    });
    // console.log('onAudioListsChange:', currentPlayId,audioLists,audioInfo)
  }

  const collapseMenu = event => {
    // document.querySelector('.navbar-toggler').click();
    document.querySelector('.navbar-collapse').classList.remove('show');
  };

  useEffect(() => {
    // window.removeEventListener('scroll', stickyMenu, false);
    window.addEventListener('scroll', stickyMenu, false); 
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  },[]);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  },[location]);

  const searchParams = new URLSearchParams(document.location.search);
  const isFrame = searchParams.get('f') === '1';

  function stickyMenu() {
    if(isFrame) return;
    
    if (window.scrollY > 100) {
      document.getElementById('main-navbar').classList.add('fixed-top');
      // add padding top to show content behind navbar
      var navbar_height = document.querySelector('.navbar').offsetHeight;
      document.body.style.paddingTop = navbar_height + 'px';
    } else {
      document.getElementById('main-navbar').classList.remove('fixed-top');
      // remove padding top from body
      document.body.style.paddingTop = '0';
    } 
  }

  return  (
    <div>
        <Helmet>
          <meta property="og:url" content={window.location.href} />
        </Helmet>
        {isFrame?null:
        <header>
          <div className="container d-flex flex-wrap justify-content-center top-header">
            <span className="main-entities me-lg-auto">
              <a href="https://www.gob.mx/cultura" className="logo-parent" target="_blank" rel="noreferrer">
                <img src={require('./images/logo-cultura.png').default} alt="Cultura" />
              </a>
              <a href="https://fonotecanacional.gob.mx/" className="logo-parent" target="_blank" rel="noreferrer">
                <img src={require('./images/logo-fonoteca.png').default} alt="Fonoteca Nacional" />
              </a>
            </span>
            <Form action="/busqueda" className="col-12 col-md-auto my-auto d-none d-md-block">
              <div className="input-group">
                <input type="search" minLength={3} className="form-control" name="t" placeholder="Buscar" aria-label="Buscar" required />
                <div className="input-group-append">
                  <button className="btn btn-secondary" type="submit"><FontAwesomeIcon icon={faSearch} size="1x" /></button>
                </div>
              </div>
            </Form>
            <span className="my-auto d-none d-md-block">
              <a className="socialnetwork" href="https://www.facebook.com/FonotecaNacionalMexico/" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a className="socialnetwork" href="https://www.instagram.com/fonotecanacional/" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a className="socialnetwork" href="https://twitter.com/Fonoteca" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faXTwitter} />
              </a>
            </span>
          </div>
        </header>}
        {isFrame?null:
        <nav id="main-navbar" className="main-nav navbar navbar-expand-lg navbar-dark">
          <div className="container">
              <Link to="/"><img className="logo-musiteca" src={require('./images/logotipo-musiteca.png').default} height="50" alt="Musiteca" /></Link>
              <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-musiteca" aria-controls="navbar-musiteca" aria-expanded="false" aria-label="Abrir navegación">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbar-musiteca">
                <ul className="nav navbar-nav">
                  {/* <li className="nav-item"><Link to="/" className="nav-link">Inicio</Link></li> */}
                  <li className="nav-item"><Link to="/acerca-de" onClick={collapseMenu} className="nav-link">Acerca de</Link></li>
                  <li className="nav-item"><Link to="/imprescindibles" onClick={collapseMenu} className="nav-link">Imprescindibles</Link></li>
                  <li className="nav-item"><Link to="/micrositios" onClick={collapseMenu} className="nav-link">Micrositios</Link></li>
                  <li className="nav-item"><Link to="/colecciones" onClick={collapseMenu} className="nav-link">Colecciones</Link></li>
                  <li className="nav-item"><Link to="/documentos" onClick={collapseMenu} className="nav-link">Documentos</Link></li>
                  <li className="nav-item"><Link to="/desde-la-fonoteca" onClick={collapseMenu} className="nav-link">Desde la fonoteca</Link></li>
                  <li className="nav-item"><Link to="/iberoamerica-suena" onClick={collapseMenu} className="nav-link">Iberoamérica Suena</Link></li>
                  <li className="nav-item"><Link to="/novedades" onClick={collapseMenu} className="nav-link">Novedades</Link></li>
                  <li className="nav-item"><Link to="/infancias" onClick={collapseMenu} className="nav-link">Infancias</Link></li>
                  {/* <li className="nav-item"><Link to="/en-directo" onClick={collapseMenu} className="nav-link">En directo</Link></li> */}
                  <li className="nav-item"><Link to="/autores" onClick={collapseMenu} className="nav-link">Autores</Link></li>
                </ul>
                <Form action="/busqueda" className="mt-3 d-block d-md-none" onSubmit={collapseMenu}>
                  <div className="input-group">
                    <input type="search" minLength={3} className="form-control" name="t" placeholder="Buscar" aria-label="Buscar" required />
                    <div className="input-group-append">
                      <button className="btn btn-secondary" type="submit"><FontAwesomeIcon icon={faSearch} size="1x" /></button>
                    </div>
                  </div>
                </Form>
                <span className="mt-1 d-block d-md-none" style={{textAlign: 'center'}}>
                  <a className="socialnetwork" href="https://www.facebook.com/FonotecaNacionalMexico/" target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                  <a className="socialnetwork" href="https://www.instagram.com/fonotecanacional/" target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faInstagram} />
                  </a>
                  <a className="socialnetwork" href="https://twitter.com/Fonoteca" target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faXTwitter} />
                  </a>
                </span>
              </div>
          </div>
        </nav>}
        <main>
          <Outlet />
          {/* <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />}>
                  
                </Route>
            </Routes>
          </BrowserRouter> */}
          <button className="btn btn-link" id="btn-go-top" onClick={() => window.scrollTo(0, 0)}><FontAwesomeIcon icon={faChevronUp} size="2x"/></button>
        </main>
        {isFrame?null:
        <footer className="copyright">
          <div className="container">
              <div id="footer" className="d-flex flex-wrap justify-content-between align-items-center">
                <div id="footer-copyright" className="col-12 col-md-3 d-flex align-items-center">
                    <span>© Derechos Reservados</span>
                </div>
                <div className="col-12 col-md-9 credits">
                  <div>
                    {/* <p><small>Con la colaboración de:</small></p> */}
                    {/* <div className="credits-logos"> */}
                      {/* <a href="https://www.sacm.org.mx/" target="_blank" rel="noreferrer">
                          <img className="logo-gobmx" src={require('./images/logo-sacm.png').default} height="30" alt="SACM" />
                      </a> */}
                      {/* <a href="https://localcontexts.org/" target="_blank" rel="noreferrer">
                          <img className="logo-gobmx" src={require('./images/logo-local-contexts.png').default} height="30" alt="Local Contexts" />
                      </a> */}
                    {/* </div> */}
                  </div>
                  <div>
                    <p><small>Con el apoyo de:</small></p>
                    <div className="credits-logos">
                      <a href="https://www.sacm.org.mx/" target="_blank" rel="noreferrer">
                          <img className="logo-gobmx" src={require('./images/logo-sacm.png').default} height="30" alt="SACM" />
                      </a>
                      <a href="https://www.emmac.mx/" target="_blank" rel="noreferrer">
                          <img className="logo-gobmx" src={require('./images/logo-emmac.png').default} height="30" alt="EMMAC" />
                      </a>
                      <a href="http://www.emmacsacm.com.mx/EMMAC/Index" target="_blank" rel="noreferrer">
                          <img className="logo-gobmx" src={require('./images/logo-emmacsacm.png').default} height="30" alt="EMMACSACM" />
                      </a>
                      <a href="https://amprofon.com.mx/es/" target="_blank" rel="noreferrer">
                          <img className="logo-gobmx" src={require('./images/logo-amprofon.jpg').default} height="30" alt="AMPROFON" />
                      </a>
                      <a href="https://ibermemoria.org/" target="_blank" rel="noreferrer">
                          <img className="logo-gobmx" src={require('./images/logo-ibermemoria.png').default} height="30" alt="IBERMEMORIA" />
                      </a>
                      <a href="https://www.segib.org/" target="_blank" rel="noreferrer">  
                          <img className="logo-gobmx" src={require('./images/logo-segib.png').default} height="30" alt="SEGIB" />
                      </a>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </footer>}
        {isFrame?null:
        <footer className="gobmx">
          <div className="container">
            <div className="d-flex flex-wrap justify-content-between align-items-center">
              <div className="col-md-4 d-flex align-items-center">
                <Link to="/">
                  <img className="logo-gobmx" src={require('./images/logo-gobmx.png').default} height="30" alt="gob.mx" />
                </Link>
                {/* <span className="text-muted">© 2021 Company, Inc</span> */}
              </div>

              <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
                {/* <li className="ms-3"><a className="text-muted" href="#"><svg className="bi" width="24" height="24"><use xlink:href="#twitter"></use></svg></a></li>
                <li className="ms-3"><a className="text-muted" href="#"><svg className="bi" width="24" height="24"><use xlink:href="#instagram"></use></svg></a></li>
                <li className="ms-3"><a className="text-muted" href="#"><svg className="bi" width="24" height="24"><use xlink:href="#facebook"></use></svg></a></li> */}
              </ul>
            </div>
          </div>
        </footer>}
        <ReactJkMusicPlayer className={isFrame?'d-none':null} {...playerOptions} audioLists={audioList} getAudioInstance={(instance) => { setPlayer(instance) }} onAudioListsChange={onAudioListsChange} />
        <VideoModal />
        <ImageModal />
        <FileModal />
        <MediaDetailModal />
    </div>
  );

}