import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMusic, faImages, faFileAlt, faMonument } from '@fortawesome/free-solid-svg-icons'
import Slider from "react-slick";
import { MediaTable, SectionCard } from '../components'
import { Config } from '../config'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Home = () => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [banners, setBanners] = useState([]);
    const [essentials, setEssentials] = useState([]);
    const [microsites, setMicrosites] = useState([]);
    const [collections, setCollections] = useState([]);
    // const [live, setLive] = useState([]);
    const [mostListened, setMostListened] = useState([]);

    useEffect(() => {
        Config.updateMetaTitle('');
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        mobileFirst: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const settingsCollections = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    useEffect(() => {
      fetch(Config.API_URL + 'home')
          .then(res => res.json())
          .then(
              (data) => {
                  setIsLoaded(true);
                  setBanners(data.banners);
                  setMicrosites(data.microsites);
                  setCollections(data.collections);
                  setMostListened(data.most_listened);
              },
              (error) => {
                  setIsLoaded(true);
                  setError(error);
              }
          )
      fetch(Config.API_URL + 'essentials')
          .then(res => res.json())
          .then(
              (data) => {
                  setEssentials(data);
              },
              (error) => {
                  setError(error);
              }
          )
        // fetch(Config.API_URL + 'lives/current?p=0&l=10')
        //     .then(res => res.json())
        //     .then(
        //         (data) => {
        //             setLive(data);
        //         },
        //         (error) => {
        //             setError(error);
        //         }
        //     )
    }, []);
    
    if (error) {
        return <div className="text-center">Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div className="text-center mt-5">Cargando...</div>;
    } else {
        // let liveSection =   <div className="row">
        //                         <div className="col-12">
        //                             <p>Actualmente no hay sesiones en curso.</p>
        //                         </div>
        //                     </div>;
        // if(live && live.length) {
        //     liveSection =   <div className="row d-flex justify-content-center text-center">
        //                         {live.map((session,index) => {
        //                             var date = Config.formatLiveDate(session);
        //                             return (
        //                               <div className="col-12 col-md-4 col-lg-3" key={`live-${index}`}>
        //                                   <SectionCard classes="card-live" section="en-directo" url={`evento/${session.url}`} name={session.name} description={date} image={session.image} imagepath="lives" />
        //                               </div>
        //                             );
        //                         })}
        //                     </div>;
        // }

        let mostListenedTable = null;
        if(mostListened.length) {
            mostListenedTable = <MediaTable items={mostListened}></MediaTable>;
        }

        return (
          <div>
            <h1 className="d-none">Musiteca</h1>
            <div id="home-carousel" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-indicators">
                  {banners.map((banner,index) => {
                      return (
                          <button type="button" key={`banner-dot-${index}`} data-bs-target="#home-carousel" data-bs-slide-to={index} className={index===0?'active':''} aria-current={index===0?'true':'false'}></button>
                      );
                  })}
              </div>
              <div className="carousel-inner">
                  {banners.map((banner,index) => {
                      return (
                          <div className={"carousel-item "+(index===0?'active':'')} key={`banner-${index}`}>
                                <a href={banner.link}>
                                    <img className="bd-placeholder-img" width="100%" height="100%" preserveAspectRatio="xMidYMid slice" focusable="false" src={Config.API_URL + 'home/banner/' + banner.file} alt={banner.title} />
                                    
                                    <div className="carousel-caption text-start">
                                        <p>{banner.title}</p>
                                    </div>
                                </a>
                          </div>
                      );
                  })}
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#home-carousel" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Anterior</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#home-carousel" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Siguiente</span>
              </button>
            </div>

            <div className="container home-wrapper">
                <section id="essentials">
                    <h2><Link to="/imprescindibles" className="nav-link">Imprescindibles</Link></h2>
                    <div className="row">
                      {essentials.map((essential,index) => {
                        return (
                          <div className="col-12 col-md-4 col-lg-3" key={`essential-${index}`}>
                            <SectionCard classes="card-essential" section="imprescindibles" url={essential.url} name={essential.name} description={essential.description} image={essential.image} imagepath="categories" />
                          </div>
                        );
                      })}
                    </div>
                </section>

                <section id="microsites" style={{ position: 'relative'}}>
                    <h2><Link to="/micrositios" className="nav-link">Micrositios</Link></h2>
                    <Slider {...settings}>
                        {microsites.map((microsite,index) => {
                          return (
                            <div key={`microsite-${index}`}>
                                <SectionCard section="micrositios" url={microsite.url} name={microsite.name+(microsite.lastname?' '+microsite.lastname:'')} image={microsite.image} imagepath="microsites" />
                            </div>
                          );
                        })}
                    </Slider>
                    <p className="see-more" style={{ position: 'absolute', right: 0, marginTop: '-15px'}}>
                        <Link to="/micrositios" className="nav-link">Ver todos los micrositios</Link>
                    </p>
                </section>

                <section id="collections">
                    <h2><Link to="/colecciones" className="nav-link">Colecciones</Link></h2>
                    <Slider {...settingsCollections}>
                      {collections.map((collection,index) => {
                        return (
                          <div key={`collection-${index}`}>
                            <Link to={`colecciones/${collection.url}`} className="card-body">
                              <img width="100%" preserveAspectRatio="xMidYMid slice" focusable="false" src={Config.API_URL + 'collections/image/' + collection.image} alt={collection.name} />
                            </Link>
                          </div>
                        );
                      })}   
                    </Slider>
                </section>
            </div>

            <div className="home-wrapper">
                <section id="documents" className="highlight">
                    <div className="container">
                        <h2><Link to="/documentos" className="nav-link">Documentos</Link></h2>
                        <div className="row d-flex justify-content-center text-center">
                            <div className="col-6 col-md-3 col-lg-2 d-flex justify-content-center align-items-center">
                                <Link to={`documentos/iconografias`}>
                                    <FontAwesomeIcon icon={faImages} size="6x" />
                                    <p>Iconografías</p>
                                </Link>
                            </div>
                            <div className="col-6 col-md-3 col-lg-2 d-flex justify-content-center align-items-center">
                                <Link to={`documentos/partituras`}>
                                    <FontAwesomeIcon icon={faMusic} size="6x" />
                                    <p>Partituras</p>
                                </Link>
                            </div>
                            <div className="col-6 col-md-3 col-lg-2 d-flex justify-content-center align-items-center">
                                <Link to={`documentos/publicaciones`}>
                                    <FontAwesomeIcon icon={faFileAlt} size="6x" />
                                    <p>Publicaciones</p>
                                </Link>
                            </div>
                            <div className="col-6 col-md-3 col-lg-2 d-flex justify-content-center align-items-center">
                                <Link to={`documentos/archivo-historico`}>
                                    <FontAwesomeIcon icon={faMonument} size="6x" />
                                    <p>Archivo Histórico</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section id="live" className="live">
                    <div className="container">
                        <h2>En vivo</h2>
                        {liveSection}
                    </div>
                </section> */}
            </div>
            
            <div className="container home-wrapper">
                <section id="collections">
                    <h2>Las más escuchadas</h2>
                    {mostListenedTable}
                </section>
            </div>

          </div>
        );
    }
}
export default Home;